// import React, { useState } from 'react';
import "./index.css"

export default function AboutUs() {
    return (<>
        <div className="centered-content full-page">
            <h1 className="page-title">About Us</h1>
            <p className="page-description">BackToNature is your platform to unite with others in promoting environmental activism and raising awareness. Together, we can take meaningful steps to protect and restore the planet.</p>
            <p className="page-description">Our goal is to move society towards the reduction of landfill dumping and the disposal of toxic waste into the environment. By offering various forum pages, we intend to help each other as a community, salvage waste across the nation to make a difference, and give back to nature what we have taken.</p>
            <p className="page-description">As high school students, we are taking initiative to make the future environmentally safe and pollution-free in addition to influencing others to join hands with us in this movement using our platform.</p>
        </div>
        <div className="left-semicircle-higher-right fun-pink"></div>
        <div className="right-semicircle-lower-left fun-yellow"></div>
    </>);
}