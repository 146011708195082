// import React, { useState } from 'react';
import "./index.css"

export default function Recycle() {
    return (<>
        <div className="centered-content full-page">
            <h1 className="page-title">What is Recycling? Why?</h1>
            <p className="page-description">Recycling is defined as “the process of collecting and reprocessing materials that would otherwise be thrown away and turning them into new products.”</p>
            <p className="page-description">By reusing materials that are not biodegradable (materials that can be broken down by nature), we are helping the environment from chemical substances that can affect ecosystems and organisms.</p>
            <p className="page-description">Materials such as paper, cardboard, plastics, glass, metal, and many more can be used to make more material, thus allowing us to reduce the tons of waste produced every year.</p>
        </div>
        <div className="top-semicircle-bottom-left"></div>
        <div className="left-semicircle-middle-right fun-blue"></div>
    </>);
}